import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { ModalActionDirective } from '../../directives/modal-action/modal-action.directive';
import { ButtonComponent } from '../button/button.component';
import { ModalNewActionsComponent } from '../modal-new/modal-new-actions/modal-new-actions.component';
import { ModalNewContentComponent } from '../modal-new/modal-new-content/modal-new-content.component';
import { ModalNewTitleComponent } from '../modal-new/modal-new-title/modal-new-title.component';
import { ModalNewComponent } from '../modal-new/modal-new.component';

@Component({
  selector: 'app-reload-this-page-modal',
  templateUrl: './reload-this-page-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    ModalActionDirective,
    ModalNewActionsComponent,
    ModalNewComponent,
    ModalNewContentComponent,
    ModalNewTitleComponent,
    TranslocoModule,
  ],
})
export class ReloadThisPageModalComponent {
  readonly #document = inject(DOCUMENT);

  onReloadButtonClick(): void {
    this.#document.location.reload();
  }
}
